var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"text":"","dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" Project ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("more_vert")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-3"},[_c('img',{attrs:{"src":item.avatar,"alt":"avatar"}})])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":item.progress,"height":"5","color":item.color}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Action")])])]}}],null,true)},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.actions),function(action){return _c('v-list-item',{key:action.text,on:{"click":function($event){return action.click(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(action.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1)]}}])}),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }