export default [
  {
    id: '150',
    product: 'iPhone6',
    price: '$699',
    status: 'processing'
  },
  {
    id: '151',
    product: 'iPad Pro',
    price: '$299',
    status: 'sent'
  },
  {
    id: '300',
    product: 'Microsoft surface',
    price: '$1,699',
    status: 'processing'
  },
  {
    id: '320',
    product: 'Galaxy S7 edge',
    price: '$729',
    status: 'processing'
  },
  {
    id: '501',
    product: '128G SD Card',
    price: '$699',
    status: 'delivered'
  }
]
